import { render, staticRenderFns } from "./SettingsSidebar.vue?vue&type=template&id=3f4b8eda&"
import script from "./SettingsSidebar.vue?vue&type=script&lang=ts&"
export * from "./SettingsSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./SettingsSidebar.vue?vue&type=style&index=0&id=3f4b8eda&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports